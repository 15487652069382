/* eslint-disable no-useless-escape */
<template>
    <div>
      <CRow>
    <CCol col="12" xl="12">
      <CCard>

        <CCardBody>


                        <a-card class="edit-card" style="margin:0; margin-top:10px" :loading="saving">
                          <span slot="title">
                            <h6 class="w-100 mb-0 text-muted" style="line-height:30px;"><span v-if="!isNaN(id) || !isNaN(mid)">Upravuje sa <span class="text-lowercase"><router-link :to="'/content/' + cttt">{{ct}}</router-link></span> </span><span v-else>Pridávate obsah <span style="text-transform: lowercase;">{{contenttype.title}}</span></span> </h6>
                            {{fieldData['title']}}</span>
                          <span slot="extra">
                            <div class="prev-next">
                 <router-link v-if="prev" :to="'/edit-content/' + prev" title="Predchádzajúca stránka"><a-button><i class="fas fa-arrow-left"></i></a-button></router-link>
                 <router-link :to="'/edit-content/' + next"  v-if="next" title="Nasledujúca stránka"><a-button><i class="fas fa-arrow-right"></i></a-button></router-link>
                </div>  <router-link :to="'/add-content/' + cttt" v-if="!isNaN(id)">
                <template>
                        <a-button type="secondary" class="ml-2">Nový<span class="ml-1" style="text-transform:lowercase"> {{ ct }}</span></a-button>
                    </template>
              </router-link>

              <template v-for="translation in translations">

              <template v-if="translation.target != id">
              <router-link :to="'/edit-content/'+translation.target" class="ml-2 float-right">
                <template>
                    <a-button type="secondary" >{{ translation.lang }}</a-button>
                </template>
              </router-link>
              </template>
              </template>

              <a-button class="ml-2" @click="openImageModal()" >Nahrať externé obrázky</a-button>
                    <a v-if="fieldData.url" :href="vars.site_url + '/' + fieldData.language + '/' + fieldData.url"  target="_blank"class="ml-2" >
                    <template>
                        <a-button type="primary">Zobraziť stránku</a-button>
                    </template>
                </a>
</span>
                        <a-tabs :animated="false">

                  <template v-for="(group,gid) in contenttype.groups">
                        <a-tab-pane :key="gid"  :tab="group.name" v-if="group.child.length">
                        <template>

                                <div class="pl-4 pr-4">
                                    <div class="row">
                        <div class="col-lg-12" :key="index" v-for="(field, index) in group.child">
                            <template v-if="field.type === '17'">
                              <label class="form-control-label mb-2">{{field.title}}:</label>
                              <span class="field-collection mb-4">
                              <draggable  @change="updateMultiOrder" v-bind="dragOptions" :key="componentKey" v-model="fieldData[field.slug]" :group="'fieldcollection' + index" @start="drag=true" @end="drag=false" handle=".handle2">
                                    <span :style="'display:inline-block; width:100%;' + (fieldData[field.slug].length > 1 ? 'border-bottom:0px solid #fff;':'')" :key="componentKey + '_' + indexx" v-for="(fiel,indexx) in fieldData[field.slug]">
                                        <div class="row" style="position:relative" v-if="field.fields">
                                        <a class="handle2" v-if="fieldData[field.slug].length > 1"><i class="fa fa-expand-arrows-alt"></i></a>
                                        <template v-for="(fieldinput,inde) in field.fields[0]">
                                        <field :styles="styles" :input="fieldinput" :key="componentKey + '_' + inde + '_' + indexx" :i="indexx" v-model="fiel[inde]" :options="fieldinput.options" :cl="field.fields[0].length" /></template>
                                        <a-icon class="del-but float-right" type="minus-circle-o" v-if="fieldData[field.slug].length > 0" @click="() => removefc(indexx,field.slug)"/></div>
                                    </span>
                              </draggable>
                                  <div v-if="field.multi === '1'" style="margin-left:20px; margin-top:18px">
                                  <a-button type="dashed" class="  float-right" @click="addColfields(field.fid)"><a-icon type="plus" style="display:block;margin-top:2px;float:left;" />Pridať</a-button>
                                  </div>
                                  </span>
                            </template>
                                           <div class="form-item-holder mb-4" v-if="field.type === '1'">
                                               <a-form-item :label="field.title">
                                               <a-input alternative=""
                                                        :key="componentKey"
                                                        input-classes="form-control-alternative"
                                                        v-model="fieldData[field.slug]"
                                            />
                                            <a-button  @click="setUrl"  v-if="field.slug === 'url' && fieldData['title']">Vygeneruj automatické url z názvu</a-button>
                                            <i class="fal fa-globe-europe auto-url" title="Preložiť" v-if="field.translatable === '1'"></i>
                                               <div class="sub-translations" v-if="field.translatable === '1'">
<div class="translation" style="position:relative;"  :key="componentKey + translate.short" v-for="translate in languages">
                                                <div  v-if="fieldData.language !== translate.short">
                                                    <a-input alternative=""
                                                               input-classes="form-control-alternative"
                                                               v-model="fieldData[field.slug + ':' + translate.short]"/>
    <i style="position:absolute;top:10px;right:15px" title="Preložiť">{{translate.title}}</i></div>
                                               </div>
                                               </div>
                                               <template v-else><div v-if="fieldData.language !== defaultLanguage && field.slug !='url'">
                                                  <a-button title="Preložiť pomocou AI" @click="translateAI(fieldData.language, fieldData[field.slug], field.slug,false)">prelož pomocou AI</a-button>
                                               </div>
                                               </template>
                                               </a-form-item>
                                           </div>


<div  v-if="field.type === '6'">
                                             <label class="form-control-label">{{field.title}}:</label>
                                               <a-input  class="mb-4" alternative=""
                                                        input-classes="form-control-alternative"
                                                        type="number"
                                                        v-model="fieldData[field.slug]"/></div>
                                            <div  class="mb-4" v-if="field.type === '5'" >
                                                  <label class="form-control-label">{{field.title}}:</label>
                                            <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        type="number"
                                                        v-model="fieldData[field.slug]" />
 </div>
                                            <div class="form-item-holder mb-4" v-if="field.type === '4'" >
                                                <label class="form-control-label">{{field.title}}:</label>
                                                <ckeditor :editor="editor" v-model="fieldData[field.slug]"  :config="editorConfig"></ckeditor>
                                                <i class="fal fa-globe-europe auto-url" title="Preložiť" v-if="field.translatable === '1'"></i>
                                                <div class="sub-translations" style="margin-top:10px;" v-if="field.translatable === '1'">
                                                    <div class="translation" style="position:relative;"  :key="componentKey + translate.short" v-for="translate in languages">
                                                        <div  v-if="fieldData.language !== translate.short">
                                                            <ckeditor :editor="editor" v-model="fieldData[field.slug + ':' + translate.short]"  :config="editorConfig"></ckeditor>
                                                            <i style="position:absolute;top:10px;right:15px">{{translate.title}}</i></div>
                                                    </div>
                                                </div>
                                                <template v-else><div v-if="fieldData.language !== defaultLanguage && field.slug !='url'">
                                                  <a-button title="Preložiť pomocou AI" @click="translateAI(fieldData.language, fieldData[field.slug], field.slug,false)">prelož pomocou AI</a-button>
                                               </div>
                                               </template>
                                            </div>
                                            <div class=" mb-4" v-if="field.type === '2'">
                                                <label class="form-control-label">{{field.title}}:</label>
                                                <a-select style="width: 100%"   :filter-option="(e,f) => compareData(e,f,field.id)" v-if="field.multi === '0'" defaultValue="1"  v-model="fieldData[field.slug]" :options="options[field.id]"/>
                                                <a-select v-else  mode="multiple" defaultValue="1" v-model="fieldData[field.slug]"   :filter-option="(e,f) => compareData(e,f,field.id)" :options="options[field.id]"/>
                                            </div>
                                            <a-checkbox class="mb-4" v-model="fieldData[field.slug]"  v-if="field.type === '7'">
                                                {{field.title}}
                                            </a-checkbox>

                                            <div class="mb-4 w-50" v-if="field.type === '3'" :key="reloadgalleries">
                                                <label class="form-control-label">{{field.title}}:</label>
                                                <image-upload :data="fieldData[field.slug]" :label="field.title" :dat="field.slug"  @changed="(e) => onChildChange(e,field.slug)"></image-upload>

                                            </div>
                                            <div class="mb-4" v-if="field.type === '19'">
                                                <label class="form-control-label">{{field.title}}:</label>
                                                 <upload :data="fieldData[field.slug]" :label="field.title" :dat="field.slug"  @changed="(e) => onChildChange(e,field.slug)"></upload>

                                            </div>


                                            <div class="mb-4" v-if="field.type === '11'" :key="reloadgalleries">


                                                <label class="form-control-label">{{field.title}}:</label>

                                                <a-upload-dragger
                                                        name="upload"
                                                        :multiple="true"
                                                        :customRequest="(e) => testo(e,field.slug)"
                                                        :headers="head"
                                                        :showUploadList="false"
                                                >
                                                    <p class="ant-upload-drag-icon" style="padding-top:20px;color:#c3e8fc">
                                                      <i class="far fa-file-image" style="font-size:46px;"></i>
                                                    </p>
                                                    <p class="ant-upload-text">
                                                     Klikni, alebo premiestni súbory do tohoto poľa
                                                    </p>

                                                </a-upload-dragger>
                                                <a-button @click="openImageModal(field.slug)" >Nahrať obrázky</a-button>
<div class="setup-panel" v-if="fieldData[field.slug] && JSON.parse(fieldData[field.slug])[Object.keys(JSON.parse(fieldData[field.slug]))[0]]">

<div class="gallery-images" :key="fieldData[field.slug]" v-if="fieldData[field.slug]">
    <draggable tag="div" @change="(e) => log(e,field.slug)" :list="JSON.parse(fieldData[field.slug])" v-bind="dragOptions" @start="drag=true" @end="drag=false"  class="list-group" handle=".handler">
    <div v-for="(imageItem,index) in JSON.parse(fieldData[field.slug])" class="gallery-image-holder" :key="'img'+field.slug+'_'+index">
        <span class="handler"><div class="title" v-if="imageItem.image.title">{{imageItem.image.title}}</div>
<div class="alttag" v-if="imageItem.image.alt">{{imageItem.image.alt}}</div>
<img class="big" :src="IMG_URL + '300:300:1' + imageItem.image.relative">
<div class="image-setup"><a-icon type="edit" @click="setupImage(imageItem.image,index,field.slug)"/><a-icon type="delete" @click="deleteThis(index,field.slug)"/></div></span>
    </div></draggable>
</div></div>
                                            </div>



<div class="mb-4" v-if="field.type === '22'">
  <label class="form-control-label">{{field.title}}:</label>
  <a-row :gutter="36">
        <a-col :span="6">
<h6>Vybrané položky</h6>
<draggable v-bind="dragOptions" v-model="fieldData[field.slug]" @change="refreshassignlist++" :group="'newcomponents' + field.slug" @start="drag=true" :key="refreshassignlist" @end="drag=false" handle=".handle" class="rearange-table">
  <transition-group>
<template v-for="(spn,ind) in fieldData[field.slug]">
  <div :key="'spn' + spn.value" class="w-100">
    <a class="handle mr-2"><i class="fa fa-expand-arrows-alt"></i></a>{{ spn.label }}
    <a class="float-right" @click="removeItemFrom(field.slug,ind)">x</a></div>
</template>
  </transition-group>
  </draggable>
        </a-col>
     <a-col :span="18">

                 <a-form-item :label-col="{span: 24}" :wrapper-col="{span: 24}"   class="mb-0">
                <a-input-search
      placeholder="Vyhľadávanie v položkách"
     @search="(e) => fetchUser(e,context[field.id])"
    />
            </a-form-item>


<a-spin v-if="fetching" slot="notFoundContent" size="small" />
<div class="finduser clickable-td" v-if="searchdata.length > 0" style="max-height:300px;overflow-y:scroll" >
<div class="mb-3">Výsledky vyhľadávania:</div>
    <table class="w-100" >
        <template  v-for="user in searchdata">
                    <tr :key="user.id" class="selectable-row">
                    <td v-if="user.image" style="width:60px;text-align:center;"><span><img :src="user.image.styled" height="40" style="max-width:100%;"></span></td>
                    <td v-else style="text-align:center;">-</td>
                    <td style="width:25%;display:block;"><templatet v-if="user.brand">{{user.brand}} - </templatet>{{user.value}}</td>
                    <td style="width:25%;display:block;"><a :href="vars.site_url + '/' + fieldData.language + '/' + user.url" target="_blank">{{user.url}}</a></td>
                    <td v-if="user.price" style="width:20%;display:block;text-align:right;">{{user.price}} €</td>
                    <td v-else style="width:20%;display:block;text-align:center;">-</td>
                    <td style="width:20%;text-align:right;display:block;"><a-button @click="addProductItem(field.slug,user)">pridať</a-button></td>
                    </tr>
        </template>
    </table>
</div>
</a-col>

</a-row>
</div>

                                            <div class="mb-4" v-if="(field.type === '13' && field.multi === '1')">
                                                <label class="form-control-label">{{field.title}}:</label>
                                                <multi v-if="fieldData[field.slug]" :data="{'item.multiplier' :JSON.parse(fieldData[field.slug]) }"  multiplier="true" :options="options[field.id]" :items="['item.multiplier']" :editid="index" @changed="(e) => onChildChangeMulti(e,field.slug)"/>
                                                <multi v-else  :items="['item.multiplier']" :options="options[field.id]" :editid="index"  multiplier="false" @changed="(e) => onChildChangeMulti(e,field.slug)"/>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '16'">
                                                <label class="form-control-label" style="width:100%">{{field.title}}:</label>
                                                <div v-if="fieldData[field.slug]">
                                                    <div v-for="(fd, index) in fieldData[field.slug]" :key="field.id + index" class="date-row">
                                                        <div v-if="fd" style="margin-left:20px;">
                                                            {{formatDateP(fd[0])}} - {{formatDateP(fd[1])}}
                                                        </div>
                                                        <a-icon class="dynamic-delete-button date-delete" type="minus-circle-o" @click="() => remove(index,field.slug)"/>
                                                    </div>
                                                </div>
                                                <div  class="ant-row-flex"><a-range-picker class="mb-10" :locale="locale" v-model="temp[field.slug]" />

                                                    <a-button type="dashed" class="mb-4" v-if="temp[field.slug]" @click="onChange(temp[field.slug],field.slug)">
                                                        <a-icon type="plus" /> Pridať
                                                    </a-button>
                                                </div>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '14'">
                                                <label class="form-control-label">{{field.title}}:</label>
                                                <a-date-picker v-model="fieldData[field.slug]" v-if="fieldData[field.slug]"  style="width: 100%" :defaultValue="formatDate(fieldData[field.slug])" :locale="locale" />
                                                <a-date-picker v-model="fieldData[field.slug]"  v-else style="width: 100%" :locale="locale" />
                                            </div>
                                            <div class="mb-4" v-if="field.type === '15'">
                                                <label class="form-control-label">{{field.title}}:</label>
                                                <multi v-if="fieldData[field.slug]" :data="JSON.parse(fieldData[field.slug])" :slug="field.slug" multiplier="false" :items="['title','text']" :editid="index" @changed="(e) => onChildChangeMultiP(e,field.slug)"/>
                                                <multi v-else  :items="['title','text']"  :editid="index" :slug="field.slug"  multiplier="false" @changed="(e) => onChildChangeMultiP(e[field.slug],field.slug)"/>
                                            </div>
                                            <div class="mb-4" v-if="( field.type === '12' && field.multi === '0')">
                                                <!---     <autoComplete slug="taxonomysearch" :data="options[field.id]" :contractor="contexts[field.id]"  :title="field.title" class="autoitem" @changed="(e) => onChildTaxChange(e,field.slug)"></autoComplete> -->
                                                <label class="form-control-label">{{field.title}} <span v-if="fieldData[field.slug]" style="float:right"><router-link :to="'/edit-taxonomy-item/' + fieldData[field.slug] + '/' + field.setup"><i class="fas fa-external-link-alt"></i></router-link></span>
                                                <a-button @click="addTerm(field)" style="float:right;margin-right:15px"    size="small">+ pridať termín</a-button></label>
                                                <a-select show-search :filter-option="(e,f) => compareData(e,f,field.id)" defaultValue="1"  style="width: 100%"  v-model="fieldData[field.slug]">
                                                    <a-select-option :value="0">Bez priradenia</a-select-option>
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>


                                            </div>
                                            <div class="mb-4" v-if="field.type === '8' && field.multi !== '1'">
                                                <label class="form-control-label">{{field.title}} <span v-if="fieldData[field.slug]" style="float:right"><router-link :to="'/edit-content/' + fieldData[field.slug]"><i class="fas fa-external-link-alt"></i></router-link></span></label>
                                                <a-select
                                                        style="width: 100%"
                                                        @change="(e) => onChildChange(e,field.slug)"
                                                        :defaultValue = "fieldData[field.slug]"
                                                        placeholder="Vyberte si zo zoznamu"
                                                >
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select> </div>
                                            <div class="mb-4" v-if="field.type === '9'">
                                                <div v-if="field.slug === 'city'">
                                                    <label class="form-control-label">{{field.title}}:</label>
                                                    <div v-if="Array.isArray(fieldData[field.slug])">{{fieldData[field.slug][1]}}</div>

                                                    <a-auto-complete
                                                            v-model="fieldData[field.slug]" :dataSource="options[field.id]" @select="(e) => onSelect(e,field.slug)" @search="(e) => onSearch(e,field.id)">   <template slot="dataSource">
                                                        <a-select-option v-for="item in dataSource" :key="item.value" :title="item.text"></a-select-option>
                                                    </template>
                                                    </a-auto-complete>
                                                </div>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '8' && field.multi === '1'">
                                                <label class="form-control-label">{{field.title}}:</label> <a-button style="position: absolute; right: 17px; top: 0;" size="small" @click="addContent(field.id)">+ pridať</a-button>
                                                <a-select v-if="fieldData[field.slug]"
                                                   :filter-option="(e,f) => compareData(e,f,field.id)"
                                                   mode="multiple" style="width: 100%"  v-model="fieldData[field.slug]" placeholder="Please select">
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>

                                               <a-select v-else
                                                          mode="multiple"
                                                          :filter-option="(e,f) => compareData(e,f,field.id)"
                                                          style="width: 100%"
                                                          @change="(e) => onChildChange(e,field.slug)"
                                                          placeholder="Please select"
                                                >
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select>


                                            </div>

                                            <div class="mb-4" v-if=" field.type === '12' && field.multi === '1'">
                                                <label class="form-control-label">{{field.title}}:</label>
                                                <a-select v-if="fieldData[field.slug]"
                                                          mode="multiple" style="width: 100%" :defaultValue = "JSON.parse(fieldData[field.slug])"
                                                          @change="(e) => onChildChange(e,field.slug)" placeholder="Please select">
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>

                                                <a-select v-else
                                                          mode="multiple"
                                                          style="width: 100%"
                                                          @change="(e) => onChildChange(e,field.slug)"
                                                          placeholder="Please select"
                                                >
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select>

                                            </div></div>

                                    </div>
                                </div>
                        </template>

                            </a-tab-pane>
                            </template>

                            <template v-if="hasBuilder"> <a-tab-pane key="112" tab="BetaEditor">
                              <betaeditor :id="id" :ct="cttt" :values="fieldData.builder" :lang="fieldData.language" data="builder" :key="refreshAll" :url="vars.site_url + '/' + fieldData.language + '/' + fieldData.url" @changed="(ee) => onChildChangeBuilder(ee,'builder')"></betaeditor>

                              </a-tab-pane></template>

                              <template v-if="hasProductOptions">
                                <a-tab-pane key="113" tab="Príslušenstvo produktu">

                                  <template>
  <div>
    <div class=" pl-4 pr-4">
      <table class="slim w-100 table tablesorter productCombo">
        <tr>
          <template v-for="(category, iio) in productOptionsCategories">
            <th :key="iio">{{ category }}</th>
          </template>
          <th>Obrázok</th>
          <th></th>
        </tr>

        <template v-for="(row, rowIndex) in fieldData.rows">
          <tr :key="rowIndex">
            <template v-for="(category, iio) in productOptionsCategories">
              <td :key="iio">



                <a-select v-model="row.selectedOptions[iio]">
                  <template v-for="af in productOptions">
                    <a-select-option v-if="af.category == iio" :key="af.produktId2.id" :value="af.produktId2.id">
                      {{ af.produktId2.title }} - {{af.produktId2.atribute_data}}
                    </a-select-option>
                  </template>
                </a-select>
              </td>
            </template>
            <td>
              <image-upload :data="row.image" :dat="rowIndex" @changed="updateImage(rowIndex, $event)"></image-upload>
            </td>
            <td>
              <a-button @click="removeRow(rowIndex)">Odobrať</a-button>
            </td>
          </tr>
        </template>
      </table>
      <div class="text-left"><a-button @click="addRow">Pridať kombináciu</a-button></div>
    </div>


  </div>
</template>
                                  </a-tab-pane>
                                </template>



                        </a-tabs>

                        <div class="col-lg-12">
                            <div class="mb-4 mt-4">

                                <div class="text-left position-absolute">
                                <template  v-if="revisions">  <a-button style="float:left; margin-top:-2px;"  class="btn btn-primary" @click="revisionsShow = true"><i class="fal fa-history" style="position:relative;top:-6px;"></i></a-button>
                                    <span class="revision-date" style="display:block;float:left;font-size:12px;line-height:14px">Posledná revízia:<br>{{formatDateP(revisions[0].created)}}</span>
                                   </template> <div v-if="created" >Vytvorené: {{created}}</div>
                      <div v-if="modified && modified != created" >Posledná zmena: {{modified}}</div>
                       </div>
                                <div class="text-right">
                                    <a-button type="secondary" class="fs-12 mr-1" @click="cloneNode">Klonovať</a-button>
                                    <a-button type="secondary"  class="fs-12 mr-1"   @click="exportNode">Export</a-button>
                                    <router-link  v-if="fieldData['language'] === 'sk'" :to="'/translate/' + id"  :href="'/translate/' + id">
                                      <a-button type="secondary" class="fs-12 mr-1">Preložiť</a-button></router-link>
                                      <a-button type="secondary" class="fs-12 mr-1" v-if="fieldData.url" :href="vars.site_url + '/' + fieldData.language + '/' + fieldData.url" target="_blank" >Zobraziť stránku</a-button>
                                    <a-popconfirm placement="topRight"
                                                  @confirm="confirm(id)"
                                                  @cancel="cancel"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                            Položka bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a-button type="danger" class=" mr-1">Zmazať</a-button>
                                    </a-popconfirm>
                                    <a-button type="primary"  class="fs-12"  @click="handleSubmit">Uložiť</a-button>
                                </div>
                            </div>
                        </div>
                        </a-card>


        <a-modal :title="'Pridajte obsah' + fieldtoselect" centered  v-model="addContentShow" :width="900" :footer="null" v-if="addContentShow" cancelText="Zrušiť" @cancel="addContentShow = false" okText="Vytvoriť" @ok="addSelect">

        <fields :id="fieldtoselect" @change="settheavalue"/>

        </a-modal>


        <a-modal title="Nový termín" centered  v-model="newTerm" v-if="newTerm" cancelText="Zrušiť" @cancel="newTerm = false" okText="Pridať" @ok="addNewTerm">
            <a-input type="text" v-model="newTermToAdd"></a-input>
        </a-modal>



        <a-modal title="Revízie" centered  v-model="revisionsShow" v-if="revisionsShow" cancelText="Zrušiť" @cancel="revisionsShow = false" okText="Vrátiť" @ok="revert">
            <a-alert class="mb-2" message="Vráti stránku do stavu z nasledujúceho času. Ak chcete zmeny uložiť, je potrebné stlačiť Uložiť (alebo použiť CTRL + S)." type="info" />
            <a-select v-model="revision" class="w-100"  placeholder="Vyberte si z predchádzajúcich verzií">
                <a-select-option v-for="op in revisions" :key="op.id">{{op.created}}</a-select-option>
            </a-select>

        </a-modal>

<a-modal v-if="showImages" v-model="showImages" @ok="showImagesDrag">



 <template v-for="(group,gid) in contenttype.groups">
                        <template  v-if="group.child.length">
                        <template>
                        <template v-for="(field, index) in group.child">
                        <div  :key="index" v-if="field.type == 3" class="d-inline-block border-1 w-100">
                        {{field.title}}
                         <a-row :gutter="12"><draggable class="list-group w-100" :data-type="field.type" :list="imagesToAdd[field.slug]"  group="imagess">
                        <template v-for="(im,index) in imagesToAdd[field.slug]">
<a-col :key="im.id" :span="4">
<img :key="index" :src="im.url" class="dgallery-image" />
</a-col>
</template>
                        </draggable>
</a-row>

                        </div>
                         <div  :key="index" v-if="field.type == 11" class="d-inline-block border-1 w-100">
                        {{field.title}}
                         <a-row :gutter="12">
                        <draggable class="list-group w-100"  :list="imagesToAdd[field.slug]"  :move="checkMove" group="imagess">
                        <template v-for="(im,index) in imagesToAdd[field.slug]">
<a-col :key="im.id" :span="4">
<img :key="index" :src="im.url" class="dgallery-image" />
</a-col>
</template>
</draggable>
</a-row>
                        </div>
                        </template>
                        </template>
                        </template>

                        </template>
     <a-row :gutter="12">
     <div class="draggable-galler-image">
     <label>Externé obrázky</label>
 <draggable :list="crawledImages" group="imagess" :move="checkMove">
<template v-for="(im,index) in crawledImages">
<a-col :key="im.id" :span="6">
<img :key="index" :src="im.url" class="dgallery-image" />
</a-col>
</template>
</draggable>
</div>
</a-row>

</a-modal>


      <a-modal v-if="showImportModalImages" v-model="showImportModalImages" @ok="crawlPageImages">
        URL produktu: <a-input v-model="crawlUrl"></a-input>
      </a-modal>
        <a-modal title="Nastavenia obrázka v galérii" centered  v-model="ImagesetupVisible" v-if="ImagesetupVisible" @ok="setupImageOK">
        <a-form-item  label="Štýl obrázka" class="image-property">
            <a-select placeholder="štýl obrázka" class="w-100" v-model="imageToSet.style" :options="styles"/>
        </a-form-item>
            <a-form-item label="Názov obrázka" class="image-property">
                <a-input placeholder="Názov"  class=" "  v-model="imageToSet.title" />
            </a-form-item>
            <a-form-item   label="Alt tag obrázka" class="image-property">
                <a-input placeholder="Alt"  class=" "  v-model="imageToSet.alt" />
            </a-form-item>
        </a-modal>



</CCardBody>
</CCard>
</CCol>
</CRow>
    </div>
</template>
<script>
    import {getURL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import ImageQ from "./content/Image";
    import Upload from "./content/Upload";

    import {IMG_URL} from "../api";

    import AUpload from "ant-design-vue/es/upload";
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import AInput from "ant-design-vue/es/input";
    import ADatePicker from "ant-design-vue/es/date-picker";
    import {Cropper} from 'vue-advanced-cropper'
    import Builder from "./content/Builder";
    import BetaEditor from "./content/BetaEditor";
    import MapLeaflet from "./content/Map"
    import Multi from "./content/multi";
    import moment from 'moment'
    import 'moment/locale/sk';
    import Field from './Field';
    import Fields from './Fields';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    // Import translations for the German language.
    import '@ckeditor/ckeditor5-build-classic/build/translations/sk';

    import draggable from "vuedraggable";
    // eslint-disable-next-line no-unused-vars
    import UploadAdapter from './UploadAdapter';

    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Začiatok", "Koniec"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storočie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }

    Vue.use(FormItem);
    Vue.use(Cropper);


    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    export default {
        name: 'editcontent',
        components: {Multi,
            Builder,
            draggable,
            AUpload,
            AInput,
            "betaeditor": BetaEditor,
            ADatePicker,
            MapLeaflet,
            "field":Field,
            "fields":Fields,AFormItem,'image-upload' : ImageQ,
            Upload},
        props: ['id','mid'],
        data() {
            return {
                url: getURL(),
                fieldData: {},
                colorsto: JSON.parse(localStorage.colors),
                formData: null,
                fileList: [],
                locale,
                fieldtoselect:false,
                IMG_URL,
                rows: [],
                gpxLoader:false,
                mapID: 0,
                dataSource:{},
                revision:false,
                newTerm:false,
                searchdata:[],
                newTermToAdd:"",
                newTermFid:"",
                showImportModalImages:false,
                languages:[],
                translations:[],
                imagesToAdd:{},
                revisions:false,
                projections:[[]],
                fetching: false,
                 drag: false,
                hasOwntitle:false,
                autourl:true,
                addContentShow: false,
                generating: false,
                refreshAll:0,
                created:false,
                next:false,
                prev:false,
                modified:false,
                lat:0,
                lng:0,
                revisionsShow:false,
                imageToSet:{},
                hasBuilder:false,
                hasProductOptions:false,
                saving:false,
                refreshassignlist:0,
                context:[],
                crawlUrl:"",
                showoptions:[],
                contexts: {},
                ImagesetupVisible:false,
                styles:[],
                temp:{},
                componentKey:0,
                translatedata:[],
                reloadimages:{},
                productOptionsCategories: false,
                productOptions: false,
                showImages:false,
                crawledImages:[],
                reloadgalleries:0,
                editor: ClassicEditor,
                editorConfig: {
                    heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Písané', class: 'ck-heading_heading3' },
                        ]
                    },
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo', '|', 'HtmlEmbed', ],
                    table: {
                        toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                    extraPlugins: [this.uploader],
                    language: 'sk',
                },
                vars:  {},
                fieldSlug:'',
                head: authHeader(),
                types: [
                    {"value": '1', "label": 'Text'},
                    {"value": '2', "label": 'Výberovník'},
                    {"value": '3', "label": 'Obrázok'},
                    {"value": '4', "label": 'Dlhý text'},
                    {"value": '5', "label": 'Celé číslo'},
                    {"value": '6', "label": 'Desatiné číslo'},
                    {"value": '7', "label": 'Logická hodnota Áno/Nie'},
                ],
                ct: null,
                ctt: null,
                cttt: null,
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
                contenttypeId: {
                    uid: this.id
                },
                options: [],
                contenttype: {
                    title: '',
                    type: '',
                },
            }
        },
        methods: {
        drop(info)
        {
        console.log(info);
        },
            IsJsonString(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            },
            checkMove: function(evt) {

if(evt.to.dataset.type)
{
if(evt.to.dataset.type == 3)
{
if(evt.to.children.length > 0)
return false;
}
}
                         },
            addSelect() {

            },
            confirm(e) {
                 dataService.axiosDelete('content', e).then(results => {
                    if (results) {
                         this.$router.push('/content/' + this.cttt)
                  }
                });
            },
            removeItemFrom(slug,ind)
            {
              this.fieldData[slug].splice(ind, 1).filter(val => val);
              this.refreshassignlist = this.refreshassignlist  + 1;
            },
            fetchUser(value,ct) {
      console.log('fetching user', value,ct);
      this.lastFetchId += 1;
      this.searchdata = [];
      this.fetching = true;
      this.fetching = false;
      dataService.axiosPost('findproducts', {searchterm: value,ctt:ct}).then(results => {
this.searchdata = results.data.data;

});

    },
    translateAI(lang,string,slug,translatable)
            {
              var response = "";
              dataService.axiosPost("translateai", {lang:lang,string:string}).then(results => {
                  response = results.data[0];

if(translatable == true)
                  this.fieldData[slug + ":" + lang] = response;
                else
                this.fieldData[slug] = response;
                  });


            },
    addProductItem(slug,theItem)
    {

this.fieldData[slug].push({value: theItem.entity_id, 'label': theItem.value});

this.refreshassignlist = this.refreshassignlist  + 1;
    },
            addTerm(field)
            {
                this.newTermFid = field;
                this.newTerm = true;
            },
            addNewTerm()
            {
                dataService.axiosPost('addfieldtaxonomy/' + this.newTermFid.id, { 'title' : this.newTermToAdd}).then(results => {
                this.options[this.newTermFid.id].push({value:results.data[0],label: this.newTermToAdd});
                this.fieldData[this.newTermFid.slug] = results.data[0];
                this.newTerm = false;
                });
            },
            settheavalue(e)
            {
  this.options[this.addContentShow].push(e);
  this.contenttype.fields.forEach(obj => {

if(obj.id === this.addContentShow)
                        {
                         if(!this.fieldData.hasOwnProperty(obj.slug))
                         {
                          this.fieldData[obj.slug] = [];
                         }
                          this.fieldData[obj.slug].push(e.value)
                        }

                    });


                this.addContentShow = false;
            },
            addContent(fieldID)
            {
            this.fieldtoselect = this.contexts[fieldID];
this.addContentShow = fieldID;
            },
            cancel() {
            },
            forceRerender() {
                this.componentKey += 1;
            },
            slugify(string) {
                const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
                const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
                const p = new RegExp(a.split('').join('|'), 'g')

                return string.toString().toLowerCase()
                    .replace(/\s+/g, '-') // Replace spaces with -
                    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                    .replace(/&/g, '-and-') // Replace & with 'and'
                    // eslint-disable-next-line no-useless-escape
                    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                    // eslint-disable-next-line no-useless-escape
                    .replace(/\-\-+/g, '-') // Replace multiple - with single -
                    .replace(/^-+/, '') // Trim - from start of text
                    .replace(/-+$/, '') // Trim - from end of text
            },
            setUrl()
            {
                this.fieldData['url'] = this.slugify(this.fieldData['title']);
                this.forceRerender();
            },
            uploader(editor)
            {
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new UploadAdapter( loader );
                };
            },
            updateImage(rowIndex, imageData) {
      // Update the image data for the corresponding row
      this.fieldData.rows[rowIndex].image = imageData;
    },
            justOneImage(slug)
            {
            this.imagesToAdd[slug] = this.imagesToAdd[slug].splice(1);

            console.log(this.imagesToAdd[slug]);
            },
            sendImage(formData,slug)
            {

                const pa = this;
                this.axios.post(this.url.API_URL + '/upload/content',
                    formData,
                    {
                        headers: authHeader(true)
                    }
                ).then(function (data) {
                    pa.datatosave = data.data[0];

                    let items = [];
// eslint-disable-next-line no-unused-vars
let maxvalue  = 0;

                    var u = {}; u.image = data.data[0];

                    if(pa.IsJsonString(pa.fieldData[slug]) && pa.fieldData[slug] !== null)
                    {
                        items = JSON.parse(pa.fieldData[slug]);
                         items.push(u);

                    }
                    else
                    {
                        items.push(u);
                    }

                    pa.fieldData[slug] = JSON.stringify(items);

                });

            },
            cloneNode()
            {
                dataService.axiosPost('translate/' + this.id, { 'lang' : this.fieldData.language}).then(results => {
                    if(results.data)
                    {this.$router.push('/edit-content/' + results.data[0])}
                });
            },
            exportNode()
            {
        const blob = new Blob([JSON.stringify(this.fieldData)], { type: 'application/json' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'export-' + this.id;
        link.click()
        URL.revokeObjectURL(link.href)
         },
            hasAlpha (context, canvas) {
                var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
                    hasAlphaPixels = false;
                for (var i = 3, n = data.length; i < n; i+=4) {
                    if (data[i] < 255) {
                        hasAlphaPixels = true;
                        break;
                    }
                }
                return hasAlphaPixels;
            },
            addRow() {
              this.fieldData.rows.push({
        selectedOptions: {}, // This will hold the selected option for each category
        image: null // This will hold the image for the row
      });
    },
    removeRow(index) {
      this.fieldData.rows.splice(index, 1); // Remove the row at the given index
    },
            testo(e,slug)
            {


                var img = new Image;
                let formData = new FormData();
                const pa = this;
                img.onload = function() {

                        formData.append('upload', e.file);

                    pa.sendImage(formData,slug);

                }

                img.src = URL.createObjectURL(e.file);

                return false
            },
            normFile(e) {

                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;


            },
            handleEdit() {
                this.$emit('changed', this.datatosave);
            },
            array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    },
    addColfields(fieldindex)
            {
          var fields =  this.contenttype.fields[fieldindex].fields[0];
          this.contenttype.fields[fieldindex].fields.push(fields);
          this.fieldData[this.contenttype.fields[fieldindex].slug][parseInt(this.fieldData[this.contenttype.fields[fieldindex].slug].length)] = new Array(fields.length).fill("");
          this.componentKey = this.componentKey + 1;
            },

            logf(e)
            {
            console.log(e);
            },
            log(e,slug)
            {
                var arr = JSON.parse(this.fieldData[slug]);


                arr = this.array_move(arr, e.moved.oldIndex, e.moved.newIndex);

                this.fieldData[slug] = JSON.stringify(arr);
            },
            removefc(ind,fieldindex)
            {
                this.contenttype.fields[fieldindex].fields.splice(ind, 1);
                this.fieldData[this.contenttype.fields[fieldindex].slug].splice(ind, 1).filter(val => val);
            },
            onChildChangeBuilder(value,slug) {
                this.fieldData[slug] = JSON.stringify(value);
            },
            formatDate(dt) {

                if(moment(dt, moment.ISO_8601, true).isValid())
                    return  moment(moment(dt).format('YYYY/MM/DD'));
                else
                    return moment(moment.unix(dt),'YYYY/MM/DD')
            },
            formatDateP(dt) {

                if(moment(dt, moment.ISO_8601, true).isValid())
                    return  moment(dt._d).format('lll');
                else
                    return moment.unix(dt).format('lll');


            },
            onChildChangeFc(input,od,ind)
            {
                this.fieldData[this.contenttype.fields[od].slug][od][ind] = input;

            },
            bool(v) {
                return v === 'true' || v === '1';
            },
            deleteThis(index,slug)
            {
                let parsed = JSON.parse(this.fieldData[slug]);
                parsed.splice(index, 1);
                this.fieldData[slug] = JSON.stringify(parsed);


            },
            onChange(e,slug)
            {

                if(!this.fieldData[slug])
                    this.fieldData[slug] = [];
                e[0] = e[0].unix();
                e[1] = e[1].unix();
                this.fieldData[slug].push(e);
                this.temp = {};
            },
            remove(index,slug)
            {
                this.fieldData[slug].splice(index, 1);
            },
            onChildChangeMulti(value,slug)
            {
                for(var i in value){
                    this.fieldData[slug] = JSON.stringify(Object.values(value[i]));
                }

            },
            setupImage(image,index,slug)
            {

                this.imageToSet = image;
                this.imageToSet.index = index;
                this.imageToSet.slug = slug;
                this.ImagesetupVisible = true;
            },
            handleGlobalEdit(e,slug)
            {
                 let parsed = JSON.parse(this.fieldData[slug]);
                 parsed.forEach(obj => {
                          obj.image.style = e;
                });

                this.fieldData[slug] = JSON.stringify(parsed);
            },

            openImageModal(fieldSlug)
            {
          this.showImportModalImages = true;
          if(fieldSlug)
          this.fieldSlug = fieldSlug;
            },

showImagesDrag()
{
console.log(this.imagesToAdd);


let fieldsToSend = [];
let ff = {};
  this.contenttype.groups.forEach(obj => {
                  if(obj.child.length)
                    {

obj.child.forEach(objj => {

if(objj.type == 3 || objj.type == 11)
fieldsToSend.push(objj);
ff[objj.slug] = objj.type;
})
                         }
});

   dataService.axiosPost('crawlpicked',{picked:this.imagesToAdd,fields:fieldsToSend}).then((results) => {

   results.data.data.forEach(us => {
   var slug = us.slug;
   let items = [];

   if(ff[slug] != "3")
   {
us.files.forEach(u => {

                   if(this.IsJsonString(this.fieldData[slug]) && this.fieldData[slug] !== null)
                   {
                     items = JSON.parse(this.fieldData[slug]);
                    items.push(u);
                 }
                 else
                {
                        items.push(u);
          }

          })

  this.fieldData[slug] = JSON.stringify(items);
  }
  else
this.fieldData[slug] = us.files;


  this.reloadgalleries++;
  });


                         });

},


             crawlPageImages()
          {

          if(this.fieldSlug)
          {






            this.showImportModalImages = false;
            dataService.axiosPost('crawlpageimage',{url:this.crawlUrl,nid:this.id}).then(results => {
             let items = [];
var slug = this.fieldSlug;
results.data.data.forEach(u => {

                   if(this.IsJsonString(this.fieldData[slug]) && this.fieldData[slug] !== null)
                   {
                     items = JSON.parse(this.fieldData[slug]);
                    items.push(u);
                 }
                 else
                {
                        items.push(u);
          }
})
                    this.fieldData[slug] = JSON.stringify(items);


                    this.reloadgalleries++;


            });
                         }
            else
            {
             dataService.axiosPost('showpageimage',{url:this.crawlUrl,nid:this.id}).then(results => {

    this.contenttype.groups.forEach(obj => {
                  if(obj.child.length)
                    {

obj.child.forEach(objj => {

if(objj.type == 3 || objj.type == 11)
this.imagesToAdd[objj.slug] = [];
})

                    }

                    });
             this.showImages = true;
             this.crawledImages = results.data.data;

             });
            }
                         },
            setupImageOK()
            {
                let parsed = JSON.parse(this.fieldData[this.imageToSet.slug]);
              parsed[this.imageToSet.index].image.title = this.imageToSet.title;
              parsed[this.imageToSet.index].image.style = this.imageToSet.style;
              parsed[this.imageToSet.index].image.alt = this.imageToSet.alt;
              this.fieldData[this.imageToSet.slug] = JSON.stringify(parsed);
                this.ImagesetupVisible = false;
            },
            onSelect(value,slug) {
                this.fieldData[slug] = value;
            },
            priceCompare()
            {

            dataService.axiosFetch("pricecompare/"+this.id).then(results => {
                    console.log(results);
                });

            },
            onSearch(e,f)
            {
                dataService.axiosFetch("dynamic/"+f, e).then(results => {
                    this.options[f] = results;
                });


            },
            jsonU(a)
            {
                var b = JSON.parse(a).map(x=>`"${x}"`).join(",")
                return JSON.parse("[ "+ b + "]");
            },
            onChildChangeMultiP(value,slug)
            {

                this.fieldData[slug] = JSON.stringify(value);
            },
            onchildMultiChange(value,slug)
            {
this.fieldData[slug] = value;
this.fetching = false;
            },
            onChildChange(value,slug) {
                this.fieldData[slug] = JSON.stringify(value);
                this.showoptions = [];
this.fetching = false;
            },
            onMapChange(value,slug) {
                this.fieldData[slug] = value.position;

this.lat = value.position.lat;
this.lng = value.position.lng;

            this.fieldData["coords"][0][0] = this.lat + ", " + this.lng;
            this.fieldData["gpspoint"] = {"lat": this.lat,"lng": this.lng};

                if(!this.hasOwntitle || !this.fieldData["title"] && value.address)
                this.fieldData["title"] = value.address;


                this.componentKey += 1;
            },
            resolveAddress(slug)
            {
            if(this.fieldData[slug])
            return this.fieldData[slug];
            else
            {
               if(this.fieldData["coords"])
               { if(this.fieldData["coords"][0][0])
            {
                let ll = this.fieldData["coords"][0][0].split(", ");
                return {"lat": parseFloat(ll[0]), "lng": parseFloat(ll[1])};
            }
            }}
            },
            onChildTaxChange (value,slug)
            {   this.fieldData[slug] = value;

            },
            handleSubmit() {
                if (!isNaN(this.id)) {
                    this.saving = true;

                    dataService.axiosPost(this.$options.name + "/" + this.id, this.fieldData).then(results => {
                        if (results.data.success) {
                            this.$message.success('Obsah bol upravený');

                            this.fetch();
                            if(results.data.data && results.data.data.revisions)
                            { this.revisions = results.data.data.revisions;
                                this.revision = this.revisions[0].id;
                            }
                         } else {
                            this.$message.error('Pri ukladaní nastala chyba');
                        }
                    });
                }
                else
                { this.saving = true;
                    dataService.axiosPost("addcontent/"+this.id, this.fieldData).then(results => {
                        if(results.data)
                        {
                            this.$router.push('/edit-content/'+results.data[0])
                        }
                    })
                }},
savePressed(e)
{
        if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.keyCode === 83) {
            if (e.repeat) { return }
            e.preventDefault();
            this.handleSubmit();
        }

},
generate()
{
    this.generating = true;
    dataService.axiosFetch("refractor", this.id).then(results => {
this.generating = false;
this.fieldData["elevation"] = results.elevation;
this.fieldData["mainview"] = results.mainview;
this.fieldData["coords"] = results.coords;

        });
},
generateGPX()
{


    this.gpxLoader = true;

               this.fieldData["mainview"] = "";
        dataService.axiosPost("generateGPX", { gps: this.fieldData["gpspoint"], title:  this.fieldData["title"] }).then(results => {
this.fieldData["file"] = results.data.data.file;
this.fieldData["json"] = results.data.data.json;
this.fieldData["centerpoint"] = results.data.data.centerpoint;
//this.fieldData["coords"][0][0] = JSON.parse(results.data.data.centerpoint)[1] + ", " + JSON.parse(results.data.data.centerpoint)[0];
this.fieldData["coords"][0][0] = results.data.data.pointLL;
this.fieldData["coords"][0][1] = results.data.data.pointDMS;

this.fieldData["length"] = results.data.data.length;

this.gpxLoader = false;
        });
},
 addedGPX(e)
            {

             if (e.fileList !== undefined)
               {
               let filedatas = e.fileList[0].response[0];
               if(!e.event)
               {
                filedatas["original"] = filedatas["uri"];
               this.fieldData["file"] = filedatas;
               this.fieldData["mainview"] = "";
               this.mapID = filedatas["original"];

 dataService.axiosPost("generateGPX", { fid: this.mapID}).then(results => {

this.fieldData["json"] = results.data.data.json;
this.fieldData["centerpoint"] = results.data.data.centerpoint;
//this.fieldData["coords"][0][0] = JSON.parse(results.data.data.centerpoint)[1] + ", " + JSON.parse(results.data.data.centerpoint)[0];
this.fieldData["coords"][0][0] = results.data.data.pointLL;
this.fieldData["coords"][0][1] = results.data.data.pointDMS;

this.fieldData["length"] = results.data.data.length;

this.gpxLoader = false;

});

               }}
            },
            fetch(){
let wtgId = this.id;

let wtg = "content";
if(this.mid)
{
wtgId = this.mid;
wtg = "track";
}

dataService.axiosFetch('translatenode/', this.id).then(results => {
                        this.translations = results;
                     });


 dataService.axiosFetch(wtg, wtgId + "/true").then(results => {
    this.fieldData = results.data
    this.created = results.created;
    this.modified = results.modified;
    this.vars = results.vars
    this.contenttype = results.contenttypes
    this.context = results.context;
    this.ctt = results.ct;
    this.hasOwntitle = this.fieldData.title;


    if(results.productOptionsCategories)
      {
        this.hasProductOptions = true;
        this.productOptionsCategories = results.productOptionsCategories;
        this.productOptions = results.productOptions;
      }
  this.contenttype.fields.forEach(obj => {
  if(obj.slug == "builder")
  this.hasBuilder = true;


  if(obj.type === '22')
  {
  if(!this.fieldData[obj.slug])
  this.fieldData[obj.slug] = [];
  }

  });

if(this.fieldData.file){
    this.mapID = this.fieldData.file.original
}

  let roleops = { "name": "Všeobecné","child": []};
  this.contenttype.groups.forEach(obj => {
  if(obj.pid === "0" && !obj.child.length)
  {
      roleops["child"].push(obj)
  }

  });
if(roleops)
{
  this.contenttype.groups = this.contenttype.groups.slice();
  this.contenttype.groups.unshift(roleops);
}
this.contexts = results.context
this.options = results.options

this.next = results.next
this.prev = results.previous

this.ct = results.ctt
  this.revisions = results.revisions;
if(this.revisions)
{
    this.revision = this.revisions[0].id;
}
this.cttt = results.cttt
this.saving = false;

                });


            },
            updateMultiOrder()
            {
this.componentKey = this.componentKey + 1;
            },
            getAllOptions(inslug)
            {
                this.showoptions = this.options[inslug];
            },
            compareData(search,inslug)
            {
              let searchTerm =search.toLowerCase();
if(inslug.componentOptions.children[0].text.toLowerCase().includes(searchTerm))
return true;
            },
            updateGPS(){

            this.fieldData["coords"][0][0] = this.lat + ", " + this.lng;
            this.fieldData["gpspoint"] = {"lat": this.lat,"lng": this.lng};
              dataService.axiosPost("generateGPX", { gps: this.fieldData["gpspoint"], title:  this.fieldData["title"] }).then(results => {
this.fieldData["file"] = results.data.data.file;
this.fieldData["json"] = results.data.data.json;
this.fieldData["centerpoint"] = results.data.data.centerpoint;
this.fieldData["coords"][0][0] = JSON.parse(results.data.data.centerpoint)[1] + ", " + JSON.parse(results.data.data.centerpoint)[0];
this.gpxLoader = false;
this.mapID = this.fieldData["file"].original;
        });
            },
            revert()
            {
              if(this.revision)
              {
                  this.fieldData = {};

                  dataService.axiosFetch("revision", this.revision).then(results => {
                      this.fieldData = results.data
                      this.vars = results.vars
                      this.contexts = results.context
                      this.options = results.options
                      this.refreshAll = this.refreshAll + 1;
                  });
                  this.revisionsShow = false;
              }
            },
            handleChange(info,slug) {

                this.fieldData[slug] = null;
                if (info.file.status === 'done') {
                    this.fieldData[slug] = info.file.response[0]
                } else if (info.file.status === 'error') {
                    this.fieldData[slug] = ""
                }
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        created() {
            document.addEventListener("keydown", this.savePressed);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.savePressed);
        },
        mounted() {

          dataService.axiosFetch('languages').then(results => {
                this.languages = results;
                results.forEach(obj => {
                    if(obj.def === "1")
                    this.defaultLanguage = obj.short;
                    this.translations[obj.short] = {};
                });

            });

          if (!isNaN(this.id) || !isNaN(this.mid) ) {

this.fetch();

            }
            else
            {

                 if(this.id)
                {
                 dataService.axiosFetch("contenttype", this.id).then(results => {

                this.contenttype = results


             let roleops = { "name": "Všeobecné","child": []};
                    this.contenttype.groups.forEach(obj => {


                        if(obj.pid === "0" && !obj.child.length)
                        {
                            roleops["child"].push(obj)
                        }
                    });
                    if(roleops)
                    {
                       this.contenttype.groups = this.contenttype.groups.slice();
                       this.contenttype.groups.unshift(roleops);
                    }
                this.fieldData = results.data
                if(Array.isArray(results.data) && !results.data.length)
                    this.fieldData = {}
                this.contexts = results.context
                this.options = results.options
                this.ct = results.ctt
            });
            }
            }
            dataService.axiosFetch('imagestyles').then(results => {
                const entries = Object.entries(results)
                this.styles.push({'value': '','title':  'Original'});
                for (const [r, key] of entries) {
                    this.styles.push({'value': r,'title':  r + ' - ' + key[0]+ ' x '+ key[1]+'px'});
                }
            });
            dataService.axiosFetch('languages').then(results => {
                this.languages = results;
                results.forEach(obj => {
                    this.translations[obj.short] = {};
                });

            });

        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }

    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .page-title
    {min-height:30px}
    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: .625rem .75rem;
    }
    .date-delete
    {float:left;margin-right:15px;margin-top:-3px;}
    .date-row{
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #e9ecfa;
        margin: 0px;
        padding: 8px;
        margin-bottom:10px;
        margin-top:-10px;
    }

    .gallery-image-holder img.big
    {height:100%;width:auto;display:block;margin:0 auto;
}

     .demo-image
    {top:0;
         position:absolute;
    width:80px;height:80px;
         border:2px solid #1d2226;
         background-color:#1d2226;
        padding:3px;
         right:-90px;
        display:none;
    }
    .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field
    {
    height: 40px;
    padding-left: 7px;
    }
    .ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 42px;
    margin-top: 0px;
    line-height: 45px;
}
    .sub-translations
    {margin-top:-20px;}
    #app .sub-translations >>> .ant-input, #app .sub-translations >>> .ant-select-selection--single, #app .sub-translations >>> input.form-control, #app .sub-translations >>> .ant-input-number
     {border-color: #dcedff;}
    .sub-translations i
    {color: #aecbdf
    }
    .demo-image img
    {max-width:100%;max-height:100%;}
    .ql-editor >>> strong, .ql-editor >>> b
    {font-weight:bold}
.gallery-images
{width:100%;display:inline-block;}
    .gallery-image-holder .title
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#1d2226;font-weight:bold;
        max-width:200px;overflow:hidden;bottom:15px;left:5px;}
    .gallery-image-holder .alttag
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#1d2226;
        max-width:200px;overflow:hidden;bottom:3px;left:5px;}
    .image-setup
    {
        display:block;
        width:100%;
        bottom:-2px;
        right:-2px;
        position:absolute;
        z-index:9;
        color:#1d2226;
        text-align:right;
    }
    .list-group
    {display:block;}
    .image-setup i:hover
    {background-color:#d31e40;
    cursor:pointer;}
    .setup-panel
    {display:inline-block;
    width:100%;position:relative;}
    .handle2
    {    position: absolute;
    left: -34px;
    background-color: #eff4ff;
    padding: 10px;
    border-radius: 6px;
    border: 2px solid #fff;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;}
    .show-example
    {position:relative;}
    .show-example img
    {margin:0 auto;}
    .show-example:hover .demo-image
    {display:block;}
    .btn-inner--icon i.fad
    {top:0}
    .action-button-gpx, .action-button-gpx >>> .ant-upload.ant-upload-select
    {
     float:right;
     margin-left:3px;
    }

    .action-button-gpx span
    { padding: 10px 0;
    }

.dgallery-image
{width:100%;display:block;
    border:1px solid #ddd;padding:5px; margin:5px;}
.draggable-gallery-image
{display:inline-block;
min-height:60px;width:100%;
border:1px solid #000;}
.prev-next
{display:block;float:left; text-align:right;}
.prev-next a
{margin-right:3px; font-size:10px;}
.prev-next a button
{ font-size:12px;}
    .image-setup i
    {
padding:10px;
        color:#fff;
        font-weight:bold;
        background-color: #000000;
        margin:2px;
    }
.productCombo >>> .beta-image img
{max-height:80px;width:auto!important; margin:0 auto;}
    #app .top-bar >>> input.ant-input
    {height:40px;}
    .form-item-holder
    {position:relative;}
   .form-control-label,.ant-select
    {width:100%;
    display:inline-block;}
    .auto-url:hover
    {cursor:pointer;
        color: #0b80e4;
        text-shadow: 0px 0px 1px #0089ff;}
    .auto-url
    {position:absolute;
        top:47px;
    right:18px;}
    .ck.ck-balloon-panel
    {z-index:999999!important;}
    .del-but{position:absolute;right:20px;top:8px;}
     .edit-card >>> .ant-card-body
     {padding:0;}
   p
{font-weight:initial!important;}

    .ant-tabs >>> .ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active
    {font-weight:initial!important}

</style>
